<template>
  <div>
    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >
      <b-row>
        <b-col cols="12">
          <div class="d-flex m-2">
            <b-button
                variant="danger"
                type="button"
                :to="{name: 'apps-email-templates-add'}"
            >
              + New template
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-table
          ref="refUserListTable"
          class="position-relative pb-3"
          :items="templates"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text="No matching records found"
      >
        <!-- Column: Name -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <span
                class="align-text-top text-capitalize"
            >
              {{ data.item.name }}
            </span>
            <!--            <small class="text-muted">{{ data.item.name }}</small>-->
          </b-media>
        </template>
        <!-- Column: Title -->
        <template #cell(title)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ data.item.title }}</span>
          </div>
        </template>
        <!-- Column: Text -->
        <template #cell(text)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ shortDescription(data.item.text) }}</span>
          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
                :to="{ name: 'apps-email-templates-edit', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="deleteTemplate(data.item.id)">
              <feather-icon icon="TrashIcon"/>
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormSelect,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue'
import store from '@/store'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useEmailList from './useEmailList'
import userStoreModule from '../user/userStoreModule'
import EmailTemplateRepository from '@/abstraction/repository/emailTemplateRepository'
// repository
const emailTemplateRepository = new EmailTemplateRepository()
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormSelect,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
  },
  data() {
    return {
      form: {
        text: null,
      },
    }
  },

  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const roleOptions = [
      {
        label: 'Admin',
        value: 'admin',
      },
      {
        label: 'Author',
        value: 'author',
      },
      {
        label: 'Editor',
        value: 'editor',
      },
      {
        label: 'Maintainer',
        value: 'maintainer',
      },
      {
        label: 'Subscriber',
        value: 'subscriber',
      },
    ]

    const planOptions = [
      {
        label: 'Basic',
        value: 'basic',
      },
      {
        label: 'Company',
        value: 'company',
      },
      {
        label: 'Enterprise',
        value: 'enterprise',
      },
      {
        label: 'Team',
        value: 'team',
      },
    ]

    const statusOptions = [
      {
        label: 'Pending',
        value: 'pending',
      },
      {
        label: 'Active',
        value: 'active',
      },
      {
        label: 'Inactive',
        value: 'inactive',
      },
    ]

    const {
      fetchTemplates,
      tableColumns,
      totalUsers,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      templates,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useEmailList()

    onMounted(async () => {
      await fetchTemplates()
    })

    return {
      // Sidebar
      fetchTemplates,
      templates,
      tableColumns,
      totalUsers,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },
  methods: {
    async deleteTemplate(templateId) {
      await emailTemplateRepository.delete(templateId)
      const index = this.templates.findIndex(x => x.id === templateId)
      this.$delete(this.templates, index)
    },
    shortDescription(value) {
      console.log(value)
      const words = value.split(' ')
      const wordsSliced = words.slice(0, 9)
      if (words.length > 10) {
        return `${wordsSliced.join(' ')}...`
      }
      return wordsSliced.join(' ')
    },
  },
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

a {
  color: $red;
}
</style>
