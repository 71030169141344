import { ref } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import EmailTemplateRepository from '@/abstraction/repository/emailTemplateRepository'

const repository = new EmailTemplateRepository()

export default function useEmailList() {
    // Use toast
    const toast = useToast()

    // const refUserListTable = ref(null)

    // Table Handlers
    const tableColumns = [
        { key: 'name', sortable: true },
        { key: 'title', sortable: true },
        { key: 'text', sortable: true },
        { key: 'actions' },
    ]
    const totalUsers = ref(0)
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const roleFilter = ref(null)
    const planFilter = ref(null)
    const statusFilter = ref(null)
    const templates = ref([])

    const fetchTemplates = async () => {
        templates.value = await repository.index()
        console.log(templates.value)
        return templates
    }

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    const resolveUserRoleVariant = role => {
        if (role === 'subscriber') return 'primary'
        if (role === 'author') return 'warning'
        if (role === 'maintainer') return 'success'
        if (role === 'editor') return 'info'
        if (role === 'admin') return 'danger'
        return 'primary'
    }

    const resolveUserRoleIcon = role => {
        if (role === 'subscriber') return 'UserIcon'
        if (role === 'author') return 'SettingsIcon'
        if (role === 'maintainer') return 'DatabaseIcon'
        if (role === 'editor') return 'Edit2Icon'
        if (role === 'admin') return 'ServerIcon'
        return 'UserIcon'
    }

    const resolveUserStatusVariant = status => {
        if (status === 'pending') return 'warning'
        if (status === 'active') return 'success'
        if (status === 'inactive') return 'secondary'
        return 'primary'
    }

    return {
        fetchTemplates,
        tableColumns,
        totalUsers,
        sortBy,
        isSortDirDesc,
        templates,

        resolveUserRoleVariant,
        resolveUserRoleIcon,
        resolveUserStatusVariant,

        // Extra Filters
        roleFilter,
        planFilter,
        statusFilter,
    }
}
