import axios from 'axios'
import url from '../url'
import {
    getArray, setQuery, getJson, setData,
} from '../resources/emailTemplateResource'

export default class EmailTemplateRepository {
    constructor(config = {}) {
        axios.defaults.errorHandle = config.errorHandle
    }

    async index() {
        const response = await axios.get(url('indexEmailTemplate'))
        if (response.status === 200) {
            return getArray(response.data)
        }
    }

    async show(EmailTemplateId) {
        const response = await axios.get(url('showEmailTemplate', { emailTemplate: EmailTemplateId }))
        if (response.status === 200) {
            return getJson(response.data.data)
        }
    }

    async store(data) {
        const json = setData(data)
        const response = await axios.post(
            url('storeEmailTemplate'),
            json,
        )
        if (response.status === 200) {
            return response.data.data
        }
    }

    async update(id, data) {
        const json = setData(data)
        const response = await axios.put(
            url('updateEmailTemplate', { emailTemplate: id }),
            json,
        )
        if (response.status === 200) {
            return response.data.data
        }
    }

    async delete(id) {
        const response = await axios.delete(url('deleteEmailTemplate', { emailTemplate: id }))
        if (response.status === 200) {
            return true
        }
    }
}
