import { SetPagination, SetQueriesObject } from '@/utils/setQueriesObject'

export const getJson = data => ({
    id: data.id,
    name: data.name,
    title: data.title,
    text: data.text,
})
export const getArray = ({ data, meta }) => {
    data = data.map(template => getJson(template))
    return data
}
export const setData = data => ({
    name: data.name,
    title: data.title,
    text: data.text,
})
export const setQuery = data => SetQueriesObject(data)
